import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"

const Privacy = () => (
  <Layout >
    <SEO title="Hone Privacy Policy" />
    <div style={{maxWidth:'880px', margin:'0 auto'}}>
    <h1>Hone<br/> Privacy Policy</h1>
    <p>This policy applies to all information collected on the Hone app or website. This policy does not address any data that Apple may collect, which may include but is not limited to, usage statistics and crash reports.
<br/><b>Information collected</b><br/>
The Hone app only collects anonymized diagnostic data upon potential errors or crashes that occur within the app. This data cannot be used to uniquely identify an individual and is only used to diagnose technical issues. Sentry.io is used to collect this data.
<br/>User content and personal data is private and stored only on your device. Hone does not store or process this data on our servers. There are no user accounts on Hone.
<br/>If you sign up to receive Hone’s newsletter, you will receive the newsletter via the email provided.<br/>
<b>Changes to this policy</b><br/>
Hone may update this privacy policy by posting a new version on this page. Please review this page occasionally and on every app update for changes. Last updated on May 18th, 2022.
<br/>© Garrett Vercoe and Eric Duong. All Rights Reserved.</p>
</div>
  </Layout>
)

export default Privacy
